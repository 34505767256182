<template>
  <div class="demand-create-desktop">
    <v-card
        :disabled="demand.loading"
        :loading="demand.loading"
        class="demand-create-desktop__form">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-plus-box"
            title="ثبت درخواست"/>
      </v-card-title>

      <v-card-text>
        <v-row class="my-4">
          <v-col :cols="3">
            <v-text-field
                dense
                v-model="demand.name"
                hide-details
                outlined
                label="نام مراجعه کننده"
            />
          </v-col>
          <v-col :cols="3">
            <v-text-field
                @change="fetchDemandByPhoneNumber()"
                dense
                v-model="demand.phone"
                hide-details
                outlined
                label="شماره تماس"
            />
          </v-col>
          <v-col :cols="3">
            <v-select
                dense
                hide-details
                outlined
                label="نوع معامله را انتخاب کنید"
                :items="transactionTypes"
                item-value="id"
                item-text="name"
                v-model="demand.transactionType"
            />
          </v-col>
          <v-col :cols="3">
            <v-select
                dense
                hide-details
                outlined
                label="نوع ملک را انتخاب کنید"
                :items="demandTypes"
                item-value="id"
                item-text="name"
                v-model="demand.propertyType"
            />
          </v-col>
        </v-row>

        <v-row>

          <v-col :cols="6">
            <v-row dense>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label
                    for="minBaseArea"
                    class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محدوده متراژ ملک
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.baseArea.min"
                      hide-details
                      outlined
                      id="minBaseArea"
                  />
                  <label for="maxBaseArea" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.baseArea.max"
                      hide-details
                      outlined
                      id="maxBaseArea"
                  />
                </div>
              </v-col>

              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minFloor" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محدوده طبقه ملک
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.floor.min"
                      hide-details
                      outlined
                      id="minFloor"
                  />
                  <label for="maxFloor" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.floor.max"
                      hide-details
                      outlined
                      id="maxFloor"
                  />
                </div>
              </v-col>

              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minBuildingAge" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محوده سن بنا
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.buildingAge.min"
                      hide-details
                      outlined
                      id="minBuildingAge"
                  />
                  <label for="maxBuildingAge" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.buildingAge.max"
                      hide-details
                      outlined
                      id="maxBuildingAge"
                  />
                </div>
              </v-col>

              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minLandArea" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  محوده مساحت زمین
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      dense
                      min="0"
                      style="max-width: 100px"
                      type="number"
                      v-model="demand.landArea.min"
                      hide-details
                      outlined
                      id="minLandArea"
                  />
                  <label for="maxLandArea" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      style="max-width: 100px"
                      dense
                      type="number"
                      v-model="demand.landArea.max"
                      hide-details
                      outlined
                      id="minLandArea"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col :cols="6">
            <v-row dense>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minTotalPrice" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  بودجه مورد نظر خرید
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      style="max-width: 150px"
                      dense
                      v-model="demand.totalPrice.min"
                      hide-details
                      outlined
                      id="minTotalPrice"
                  />
                  <label for="maxTotalPrice" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      dense
                      style="max-width: 150px"
                      v-model="demand.totalPrice.max"
                      hide-details
                      outlined
                      id="maxTotalPrice"
                  />
                </div>
              </v-col>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minPricePerMeter" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  بودجه مورد نظر اجاره
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-text-field
                      style="max-width: 150px"
                      dense
                      v-model="demand.pricePerMeter.min"
                      hide-details
                      outlined
                      id="minPricePerMeter"
                  />
                  <label for="maxPricePerMeter" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-text-field
                      dense
                      style="max-width: 150px"
                      v-model="demand.pricePerMeter.max"
                      hide-details
                      outlined
                      id="maxPricePerMeter"
                  />
                </div>
              </v-col>
              <v-col :cols="12" class="d-flex align-center justify-space-between">
                <label for="minViewDay" class="font-weight-bold text-body-1 ml-3 flex-grow-0">
                  روز های بازدید
                </label>
                <div class="flex-grow-1 pl-2">
                  <v-divider/>
                </div>
                <div class="d-flex align-center justify-end flex-grow-0">
                  <v-select
                      :items="daysOfWeek"
                      item-value="id"
                      item-text="name"
                      style="max-width: 150px"
                      dense
                      v-model="demand.viewDay.min"
                      hide-details
                      outlined
                      id="minViewDay"
                  />
                  <label for="maxViewDay" class="font-weight-bold text-body-1 mx-2">
                    تا
                  </label>
                  <v-select
                      :items="daysOfWeek"
                      item-value="id"
                      item-text="name"
                      dense
                      style="max-width: 150px"
                      v-model="demand.viewDay.max"
                      hide-details
                      outlined
                      id="maxViewDay"
                  />
                </div>
              </v-col>
              <v-col :cols="12">
                <v-row dense>
                  <v-col :cols="3">
                    <v-text-field
                        type="number"
                        dense
                        v-model="demand.days"
                        hide-details
                        outlined
                        label="مهلت زمان (روز)"
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                        dense
                        type="number"
                        v-model="demand.liquidityPercentage"
                        hide-details
                        outlined
                        label="درصد نقدینگی(%)"
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                        type="number"
                        dense
                        v-model="demand.personCount"
                        hide-details
                        outlined
                        label="تعداد نفرات"
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-text-field
                        type="number"
                        dense
                        v-model="demand.numberOfSleeps"
                        hide-details
                        outlined
                        label="تعداد خواب"
                    />
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-col>

        </v-row>


        <v-row class="my-4">
          <v-col
              v-for="(property ,index) in properties"
              :key="index"
              :cols="2">
            <v-checkbox
                v-model="demand.properties"
                hide-details
                dense
                :value="property.id"
                :label="property.name"
            />
          </v-col>
        </v-row>

        <v-row class="my-4">
          <v-col :cols="12">
            <v-combobox
                v-model="demand.locations"
                class="mt-2"
                label="مناطق درخواستی"
                item-value="id"
                item-text="name"
                :items="locations"
                multiple
                outlined
                chips
                deletable-chips
                small-chips
            />

            <v-textarea
                v-model="demand.description"
                outlined
                height="150"
                label="توضیحات"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="mt-4" :cols="12">
            <v-btn
                :loading="demand.loading"
                color="primary" large @click="submitDemand">
              ثبت درخواست
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-dialog v-model="table.modal">
      <v-card>
        <v-card-title class="flex align-center justify-space-between">
          <div>
            درخواست های تکراری
          </div>

          <v-btn color="danger" @click="table.modal = false">
            بستن
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-4">
          <table class="w-100">
            <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-center">کاربر</th>
              <th class="text-center">عنوان</th>
              <th class="text-center">تاریخ ثبت</th>
              <th class="text-center">وضعیت</th>
              <th class="text-center">عملیات</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item,index) in table.items"
                :key="index"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.clientName }}</td>
              <td class="text-center">{{ item.title }}</td>
              <td class="text-center">{{ item.created_at }}</td>
              <td class="text-center">
                <v-chip color="success" text-color="white" v-if="item.status">
                  تایید شده
                </v-chip>
                <v-chip color="error" text-color="white" v-else>
                  تایید نشده
                </v-chip>
              </td>
              <td class="text-center">
                <EditButton
                    @click="item.actions.edit"
                />
                <DeleteButton
                    @click="item.actions.delete"
                />

              </td>
            </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این درخواست مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import ViewButton from "@/components/ViewButton"
import EditButton from "@/components/EditButton"
import DeleteButton from "@/components/DeleteButton"
import PageTitle from "@/components/PageTitle"
import {all, destroy, store} from "@Newfiling/module-demand/src/api";
import {AppConfig} from "@Newfiling/Services";
import Vue from "vue";
import {addCommas, removeCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopFileCreate',

  components: {
    PageTitle,
    ViewButton,
    EditButton,
    DeleteButton,
  },

  data() {
    return {
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      transactionTypes: [],
      demandTypes: [],
      daysOfWeek: [
        {
          id: 0,
          name: "شنبه"
        },
        {
          id: 1,
          name: "یکشنبه"
        },
        {
          id: 2,
          name: "دوشنبه"
        },
        {
          id: 3,
          name: "سه شنبه"
        },
        {
          id: 4,
          name: "چهار شنبه"
        },
        {
          id: 5,
          name: "پنج شنبه"
        },
        {
          id: 6,
          name: "جمعه"
        },
      ],
      properties: [],
      locations: [],
      demand: {
        loading: false,
        name: "",
        phone: "",
        transactionType: "",
        propertyType: "",
        numberOfSleeps: "",
        baseArea: {
          min: "",
          max: "",
        },
        floor: {
          min: "",
          max: "",
        },
        buildingAge: {
          min: "",
          max: "",
        },
        landArea: {
          min: "",
          max: "",
        },
        totalPrice: {
          min: '',
          max: '',
        },
        pricePerMeter: {
          min: '',
          max: '',
        },
        viewDay: {
          min: '',
          max: '',
        },
        days: '',
        liquidityPercentage: '',
        personCount: '',
        personal: false,
        realEstate: false,
        properties: [],
        locations: [],
        description: ''
      },
      table: {
        modal: false,
        headers: [
          '#',
          'کاربر',
          'عنوان',
          'تاریخ ثبت',
          'وضعیت',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
    }
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfigs = (await AppConfig) || {};
      this.transactionTypes = appConfigs?.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name:item.Id === 1 ? 'خرید' :  item.Typeoftransaction,
        }
      })
      this.demandTypes = appConfigs?.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.properties = appConfigs.DemandFeaturesTB.map(item => {
        return {
          id: item.Id,
          name: item.Persianname,
        }
      })
      this.locations = appConfigs?.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
    })
  },

  methods: {
    async submitDemand() {
      this.demand.loading = true;
      try {
        const normalize = (value) => {
          if (String(value).trim() === '') {
            return 0;
          }
          return value;
        }
        const data = {
          "Requesttitle": "test",
          clientName: this.demand.name,
          numberOfSleeps: this.demand.numberOfSleeps,
          Mobile: this.demand.phone,
          Typeofdemand: normalize(this.demand.transactionType),
          PropertyType: normalize(this.demand.propertyType),
          minmummetraj: normalize(this.demand.baseArea.min),
          maximummetraj: normalize(this.demand.baseArea.max),
          floorNumber: normalize(this.demand.floor.min),
          floorNumber2: normalize(this.demand.floor.max),
          minimumbuildingage: normalize(this.demand.buildingAge.min),
          maximumbuildingage: normalize(this.demand.buildingAge.max),
          landarea: normalize(this.demand.landArea.min),
          landarea2: normalize(this.demand.landArea.max),
          minimumSalesBudget: normalize(removeCommas(this.demand.totalPrice.min)),
          maximumSalesBudget: normalize(removeCommas(this.demand.totalPrice.max)),
          minimumRentBudget: normalize(removeCommas(this.demand.pricePerMeter.min)),
          maximumRentBudget: normalize(removeCommas(this.demand.pricePerMeter.max)),
          startdayTheDayOfTheVisit: this.demand.viewDay.min,
          enddayTheDayOfTheVisit: this.demand.viewDay.max,
          deadline: normalize(this.demand.days),
          liquidityPercentage: this.demand.liquidityPercentage,
          NumberOfPeople: normalize(this.demand.personCount),
          zonkenPersonal: true,
          zonkenRealEstat: true,
          Attributes: this.demand.properties.join('-'),
          description: this.demand.description,
          neighborhoods: this.demand.locations.map(item => item.id).join('-'),
        };
        await store(data);
        this.reset();
        this.$toast.success('درخواست با موفقیت ثبت شد.');
        // await this.$router.push('/panel/demands/list');
      } catch (e) {
        console.log(e)
      }
      this.demand.loading = false;
    },
    async fetchDemandByPhoneNumber() {
      try {
        this.demand.loading = true;
        const res = (await all({
          phone1: this.demand.phone,
          take: 10,
          skip: 0,
        }))?.data;
        const getTitle = (item) => {
          let title = this.transactionTypes.find(i => i.id === item.typeofdemand)?.name + " ";
          title = title + this.demandTypes.find(i => i.id === item.propertyType)?.name + " در ";
          title = title + this.locations.filter(i =>
              [item.locationsid1, item.locationsid2, item.locationsid3].includes(i.id))
              .map(i => i.name)
              .join(' ، ')
          return title;
        }
        this.table.items = res?.message.map(item => {
          return {
            clientName: item.clientName,
            title: getTitle(item),
            created_at: item.solarDateofRegistration,
            status: item.filestatus,
            actions: {
              view: () => {
                this.demand = {
                  ...this.demand,
                  transactionType: this.fileTransactions.find(i => i.id === item.typeofdemand)?.name,
                  fileProperty: this.fileTypes.find(i => i.id === item.propertyType)?.name,
                  username: item.clientName,
                  phoneNumber: item.mobile,
                  deadline: item.deadline + ' روز ',
                  liquidityPercentage: (() => {
                    if (item.liquidityPercentage) {
                      return item.liquidityPercentage + ' % ';
                    }
                  })(),
                  numberofpeople: (() => {
                    if (item.numberofpeople) {
                      return item.numberofpeople + ' نفر'
                    }
                  })(),
                  visitDays: (() => {
                    if (item.startdayTheDayOfTheVisit && item.enddayTheDayOfTheVisit) {
                      return this.daysOfWeek.find(i => i.id === item.startdayTheDayOfTheVisit)?.name + ' تا ' + this.daysOfWeek.find(i => i.id === item.enddayTheDayOfTheVisit)?.name
                    }
                  })(),
                  baseArea: (() => {
                    if (item.minmummetraj && item.maximummetraj) {
                      return `${item.minmummetraj} تا ${item.maximummetraj} متر مربع `
                    }
                  })(),
                  landArea: (() => {
                    if (item.landarea1 && item.landarea2) {
                      return `${item.landarea1} تا ${item.landarea2} متر مربع `;
                    }
                  })(),
                  floorNumber: (() => {
                    if (item.floornumber1 && em.floornumber2) {
                      return `${item.floornumber1} تا ${item.floornumber2}`;
                    }
                  })(),
                  age: (() => {
                    if (item.minimumbuildingage && item.maximumbuildingage) {
                      return `${item.minimumbuildingage} تا ${item.maximumbuildingage} سال `
                    }
                  })(),
                  properties: this.properties.filter(i => item.attributes?.split('-').map(j => parseInt(j)).includes(i.id)),
                  locations: this.locations.filter(i => [item.locationsid1, item.locationsid2, item.locationsid3].includes(i.id)),
                  description: item.description,
                  totalPrice: (() => {
                    if (item.minimumSalesBudget && item.maximumSalesBudget) {
                      return `${addCommas(item.minimumSalesBudget)} تا ${addCommas(item.maximumSalesBudget)} تومان `;
                    }
                  })(),
                  pricePerMeter: (() => {
                    if (item.minimumRentBudget && item.maximumRentBudget) {
                      return `${addCommas(item.minimumRentBudget)} تا ${addCommas(item.maximumRentBudget)} تومان `;
                    }
                  })(),
                  modal: true,
                  title: getTitle(item),
                }
              },

              edit: () => {
                this.$router.push('/panel/demands/edit/' + item.id)
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },
            }
          }
        });
        this.table.totalCount = res?.recordcount;
        this.table.modal = res?.message?.length;
      } catch (e) {
        console.log(e)
      }
      this.demand.loading = false;
    },
    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await destroy(this.deleteItem.id);
        this.$toast.success('درخواست مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        this.table.modal = false;
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },
    reset() {
      this.demand = {
        ...this.demand,
        numberOfSleeps: "",
        name: "",
        phone: "",
        transactionType: "",
        propertyType: "",
        baseArea: {
          min: "",
          max: "",
        },
        floor: {
          min: "",
          max: "",
        },
        buildingAge: {
          min: "",
          max: "",
        },
        landArea: {
          min: "",
          max: "",
        },
        totalPrice: {
          min: '',
          max: '',
        },
        pricePerMeter: {
          min: '',
          max: '',
        },
        viewDay: {
          min: '',
          max: '',
        },
        days: '',
        liquidityPercentage: '',
        personCount: '',
        personal: false,
        realEstate: false,
        properties: [],
        locations: [],
        description: ''
      }
      window.scrollTo(0, 0);
    }
  },

  watch: {
    'demand.totalPrice.min'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.totalPrice.min = result);
    },
    'demand.totalPrice.max'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.totalPrice.max = result);
    },
    'demand.pricePerMeter.min'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.pricePerMeter.min = result);
    },
    'demand.pricePerMeter.max'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.demand.pricePerMeter.max = result);
    },
  }

}
</script>

<style scoped>
</style>


